<script lang="ts" setup>
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import type { TypeBlockDualText } from "~/types/contentful";
import nodeRenderers from "~/lib/node-renderers.js";

defineProps<{
  fields: TypeBlockDualText<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"] & {
    stats: { key: string; value: string }[];
  };
}>();
</script>

<template>
  <UiContainer class="block-text relative bg-white">
    <section class="relative">
      <h2>
        {{ fields.title }}
      </h2>

      <div class="grid grid-cols-12 gap-y-4 md:gap-x-16">
        <div
          v-if="fields.contentLeft"
          class="col-span-12 pt-1 lg:col-span-6"
        >
          <div class="rich-text">
            <RichTextRenderer
              :document="fields.contentLeft"
              :node-renderers="nodeRenderers"
            />
          </div>
        </div>

        <div
          v-if="fields.stats && fields.stats.length > 0"
          class="col-span-12 pt-1 lg:col-span-6"
        >
          <div class="grid grid-cols-2 gap-3 md:gap-4">
            <div
              v-for="(stat, index) in fields.stats"
              :key="index"
            >
              <div
                class="mb-3 text-[2.5rem] font-bold text-blue md:text-desktop-4xl lg:text-[3.5rem]"
              >
                {{ stat.key }}
              </div>
              <div class="max-w-52">
                {{ stat.value }}
              </div>
            </div>
          </div>

          <hr
            class="mb-5 mt-10 border-b-[6px] border-t-0 border-b-green md:border-b-[7px] lg:max-w-[calc(50%+13rem)]"
          />
        </div>

        <div
          v-if="fields.contentRight"
          class="col-span-12 pt-1 lg:col-span-6"
        >
          <div class="rich-text">
            <RichTextRenderer
              :document="fields.contentRight"
              :node-renderers="nodeRenderers"
            />
          </div>
        </div>
      </div>
    </section>
  </UiContainer>
</template>
