<script lang="ts" setup>
import type { EntrySys } from "contentful";
import RichTextRenderer from "contentful-rich-text-vue-renderer";
import { isActiveNav } from "~/lib/routing.js";
import type { TypeNavigationMain } from "~/types/contentful";
import nodeRenderers from "~/lib/node-renderers.js";

const { isMobile } = useResponsive();

const overlay = useOverlay();

const props = defineProps<{
  fields: TypeNavigationMain<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
  sys: EntrySys;
}>();

// We source nav items from here, instead of through contentful's resolver,
// so we have smaller payloads
const { data: navItems } = await useFetch("/api/nav", {
  query: { id: props.sys.id },
});

const visibleDropdown = ref<number | null>(null);
const dropdown = ref<HTMLElement | null>(null);

const route = useRoute();

const isSidebarOpen = ref(false);

const toggleSidebar = () => {
  isSidebarOpen.value = !isSidebarOpen.value;
};

watch(route, () => (visibleDropdown.value = null));

const onMouseClick = (index: number) => {
  if (visibleDropdown.value === index) {
    displayDropdown(false);
  } else {
    displayDropdown(true, index);
  }
  // }
  window.addEventListener("scroll", handleScroll);
};

const onNavigate = () => {
  displayDropdown(false);
  isSidebarOpen.value = false;
};

const onDropdownClickOutside = (event: Event) => {
  if (!isMobile.value) {
    if ((event.target as HTMLElement).id !== "dropdown") {
      displayDropdown(false);
    }
  }
};

const handleScroll = () => {
  if (!isMobile.value) {
    if (window.scrollY > 200) {
      displayDropdown(false);
      window.removeEventListener("scroll", handleScroll);
    }
  }
};

const displayDropdown = (displayState: boolean, index?: number) => {
  visibleDropdown.value = displayState && index !== undefined ? index : null;
  overlay.value = displayState;
};
</script>

<template>
  <header class="flex h-[68px] items-center lg:h-[94px]">
    <div class="container relative mx-auto flex items-center justify-between">
      <div class="flex w-full items-center justify-between">
        <!-- Logo -->
        <div class="flex h-10 max-lg:order-2">
          <NuxtLink
            to="/"
            class="w-[125px]"
          >
            <NuxtIcon
              name="ussif:logo"
              class="text-navy-dark size-full lg:text-white"
            />
          </NuxtLink>
        </div>
        <!-- Search -->
        <NuxtLink
          to="/search"
          class="hidden size-10 items-center justify-center max-lg:order-1"
        >
          <NuxtIcon
            name="ussif:search"
            size="16"
          />
        </NuxtLink>
        <!-- Sidebar toggle -->
        <button
          class="flex size-10 items-center justify-center max-lg:order-3 lg:hidden"
          @click="toggleSidebar"
        >
          <NuxtIcon
            name="ussif:hamburger"
            size="24"
          />
        </button>
        <!-- Desktop Menu -->
        <!-- Navigation Links -->
        <nav class="flex bg-navy pr-4 max-lg:hidden lg:space-x-6 xl:space-x-8">
          <div
            v-for="(item, index) in navItems"
            :key="index"
            class=""
            :class="{
              active: isActiveNav(item, route),
            }"
          >
            <!-- top level nav items -->
            <NuxtLink
              v-if="'link' in item && 'label' in item"
              :to="item.link"
              class="btn--link text-white"
            >
              {{ item.label }}
            </NuxtLink>

            <!-- dropdown nav item -->
            <div
              v-else-if="'primaryLinks' in item"
              class=""
            >
              <div
                class="flex items-center"
                @click="onMouseClick(index)"
              >
                <span class="btn--link text-white">
                  {{ item.name }}
                </span>
                <NuxtIcon
                  name="ussif:arrow"
                  class="ms-2 text-sm text-white transition"
                  :class="{
                    'rotate-180': visibleDropdown === index,
                  }"
                />
              </div>

              <!-- dropdown -->
              <Transition name="fade">
                <template v-if="'primaryLinks' in item">
                  <div
                    v-if="visibleDropdown === index"
                    ref="dropdown"
                    v-click-outside="onDropdownClickOutside"
                    class="absolute left-0 top-16 z-40 w-full overflow-hidden rounded bg-white shadow-md"
                  >
                    <div class="relative grid grid-cols-12 gap-4 bg-white">
                      <!-- dropdown promo -->
                      <div class="relative col-span-3">
                        <NuxtLink
                          :to="getUrl(item.promo.fields.link)"
                          @click="onNavigate"
                        >
                          <NuxtImg
                            v-if="item.promo.fields.image"
                            :src="item.promo.fields.image.fields.file?.url"
                            :alt="item.promo.fields.name"
                            :width="314"
                            :height="419"
                            fit="cover"
                          />
                          <div class="absolute bottom-0 bg-black/80 p-6 text-center text-white">
                            <h4>
                              {{ item.promo.fields.name }}
                            </h4>
                            <p class="mt-2">
                              {{ item.promo.fields.copy }}
                            </p>
                          </div>
                        </NuxtLink>
                      </div>

                      <!-- dropdown links -->
                      <div class="col-span-9 p-20">
                        <div
                          v-if="item.primaryLinks.length"
                          class="grid grid-cols-12 gap-12"
                        >
                          <NuxtLink
                            v-for="(primaryLink, primaryLinkIndex) in item.primaryLinks"
                            :key="primaryLinkIndex"
                            :to="primaryLink.link"
                            class="col-span-12"
                            @click="onNavigate"
                          >
                            <div class="flex w-fit items-center border-b-2 border-blue pb-2">
                              <span class="me-4 font-bold text-blue">
                                {{ primaryLink.label }}
                              </span>
                              <NuxtIcon
                                name="ussif:arrow-right"
                                class="size-4 text-blue"
                              />
                            </div>
                          </NuxtLink>

                          <div
                            v-for="(link, linkIndex) in item.items"
                            :key="linkIndex"
                            class="col-span-6"
                          >
                            <NuxtLink
                              :key="linkIndex"
                              :to="link.link"
                              @click="onNavigate"
                            >
                              <span class="btn--link bg-[linear-gradient(#000,#000)] font-bold">
                                {{ link.label }}
                              </span>
                              <p class="pt-2">
                                {{ link.copy }}
                              </p>
                            </NuxtLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </Transition>
            </div>
          </div>
        </nav>
        <!-- Right Side Buttons -->
        <div class="hidden items-center justify-end space-x-4 lg:flex">
          <UiButton
            button-type="primary"
            button-theme="dark"
          >
            <a href="/join"> Join Us </a>
          </UiButton>
        </div>
      </div>
    </div>
    <!-- Mobile Menu -->
    <div
      class="fixed inset-y-0 z-20 w-[288px] bg-white shadow-lg transition-all duration-300 lg:hidden"
      :class="isSidebarOpen ? 'right-0' : '-right-[288px]'"
    >
      <div class="relative h-[68px] px-6">
        <button
          class="absolute right-6 top-1/2 -translate-y-1/2 focus:outline-none"
          @click="toggleSidebar"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="size-7"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div class="h-full overflow-scroll">
        <nav class="flex flex-col px-10 py-2">
          <div
            v-for="(item, index) in navItems"
            :key="index"
            class=""
          >
            <NuxtLink
              v-if="'link' in item"
              :to="item.link"
              class="block py-2 text-xl font-bold"
              @click="onNavigate"
            >
              {{ "label" in item ? item.label : item.name }}
            </NuxtLink>

            <div
              v-else-if="'primaryLinks' in item"
              class=""
            >
              <div
                class="flex items-center justify-between py-3 text-xl font-bold"
                @click="onMouseClick(index)"
              >
                <span class="">
                  {{ item.name }}
                </span>
                <NuxtIcon
                  name="ussif:arrow-right"
                  class="text-sm"
                />
              </div>
            </div>

            <!-- dropdown -->
            <Transition name="fade">
              <template v-if="'primaryLinks' in item">
                <div
                  v-if="visibleDropdown === index"
                  ref="dropdown"
                  v-click-outside="onDropdownClickOutside"
                  class="absolute left-0 top-[68px] z-40 size-full overflow-hidden bg-white"
                >
                  <div class="relative h-full overflow-y-scroll bg-white px-10 py-6">
                    <!-- dropdown links -->
                    <div
                      v-if="item.primaryLinks.length"
                      class="grid grid-cols-1 gap-y-8 pb-[200px]"
                    >
                      <div
                        class="flex items-center"
                        @click="onMouseClick(index)"
                      >
                        <div class="me-4 rotate-180">
                          <NuxtIcon
                            name="ussif:arrow-right"
                            class="text-sm"
                          />
                        </div>
                        <span class="font-bold">
                          {{ item.name }}
                        </span>
                      </div>
                      <NuxtLink
                        v-for="(primaryLink, primaryLinkIndex) in item.primaryLinks"
                        :key="primaryLinkIndex"
                        :to="primaryLink.link"
                        @click="onNavigate"
                      >
                        <div class="flex w-fit items-center border-b-2 border-blue pb-2">
                          <span class="me-4 font-bold text-blue">
                            {{ primaryLink.label }}
                          </span>
                          <NuxtIcon
                            name="ussif:arrow-right"
                            class="size-4 text-blue"
                          />
                        </div>
                      </NuxtLink>

                      <div
                        v-for="(link, linkIndex) in item.items"
                        :key="linkIndex"
                      >
                        <NuxtLink
                          :key="linkIndex"
                          :to="link.link"
                          @click="onNavigate"
                        >
                          <span class="font-bold">
                            {{ link.label }}
                          </span>
                          <p class="pt-2">
                            {{ link.copy }}
                          </p>
                        </NuxtLink>
                      </div>
                    </div>
                  </div>
                  <!-- dropdown promo -->
                  <div class="absolute bottom-[68px]">
                    <NuxtLink
                      :to="item.promo.link"
                      class="relative block"
                      @click="onNavigate"
                    >
                      <NuxtImg
                        v-if="item.promo.fields.image"
                        :src="item.promo.fields.image.fields.file?.url"
                        :width="288"
                        :height="111"
                        fit="cover"
                        loading="lazy"
                        :alt="item.promo.fields.name"
                        class="absolute size-full object-cover"
                      />
                      <div class="relative bg-black/40 px-10 py-6 text-white">
                        <h4 class="text-xl font-bold">
                          {{ item.promo.fields.name }}
                        </h4>
                        <p class="mt-1">
                          {{ item.promo.fields.copy }}
                        </p>
                      </div>
                    </NuxtLink>
                  </div>
                </div>
              </template>
            </Transition>
          </div>
        </nav>
        <div class="mb-[100px]">
          <div class="copy px-10 py-4">
            <RichTextRenderer
              :document="props.fields.copy"
              :node-renderers="nodeRenderers"
            />
          </div>
          <!-- Right Side Buttons -->
          <div class="flex items-center space-x-4 px-10 pt-4 lg:hidden">
            <UiButton
              button-type="primary"
              button-theme="dark"
            >
              <a href="/join"> Join Us </a>
            </UiButton>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
:deep(.copy) {
  a {
    @apply text-blue font-bold;
  }
}
</style>
